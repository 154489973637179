<template>

  <!-- Post -->
    <section class="post">
      <header class="major">
        <h1>Ota yhteyttä</h1>
        <p>Kerro meille millaista palvelua tarvitset, oli kyse sitten pienestä äänentoistosta hääjuhliin, musiikista ravintolan terassille tai pienimuotoisesta kesäfestarista!</p>

        <p>
          <b>Äänentoisto & DJ-palvelut</b><br/>
          <a href="tel:0400472668" tel>0400 472 668 / Janne</a>
        </p>
        <p>
          <b>Valoshow, äänentoisto & bändi-palvelut</b><br/>
          <a href="tel:0408232228">040 823 2228 / Harri</a>
        </p>
        <p>
          <b>Sähköposti</b><br/>
          <a href="#">4momentum.fin@gmail.com</a>
        </p>
        <p>
          <b>Facebook & Messenger</b><br/>
          <a href="#">https://www.facebook.com/4momentum.fin</a>
        </p>
      </header>

    </section>

</template>

<script>
export default {
  name: "contact-view",
  computed: {
  },
  data: () => ({
    needs: {
      pa: false,
      lights: false,
      live: false,
      dj: false,
      mixing: false
    }
  })
}
</script>

<style lang="scss">
section.post {
  button.button {
    border: 0;
    box-shadow: none;

    background: #eee;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &.active {
      background: #58a758;
      color: white !important;
    }
  }
}
</style>