<template>

  <!-- Post -->
    <section class="post">
      <header class="major">
        <h1>Hinnasto</h1>
        <p>Alta löydät <b>ohjehinnastomme</b>, sekä palvelusisältöjen kuvaukset.<br/>Huomioithan että lopulliseen hintaan vaikuttavat mm. toimitusaika, matka sekä mahdolliset erityistoiveet.</p>
        <p>Ota rohkeasti yhteyttä ja kysy juuri tarpeisiinne räätälöityä palvelua!</p>
      </header>

      <div>Tarvitsen tapahtumaani...</div>

      <div id="price-requirements">
        <button class="button small" :class="{'active': needs.pa }" @click="needs.pa = !needs.pa">Ääntä</button>
        <button class="button small" :class="{'active': needs.lights }" style="margin-left: 1rem;" @click="needs.lights = !needs.lights">Valoja</button>
        <button class="button small" :class="{'active': needs.mixing }" style="margin-left: 1rem;" @click="needs.mixing = !needs.mixing">Miksauspalvelut</button>
        <button class="button small" :class="{'active': needs.live }" style="margin-left: 1rem;" @click="needs.live = !needs.live">Live-musiikkia</button>
        <button class="button small" :class="{'active': needs.dj }" style="margin-left: 1rem;" @click="needs.dj = !needs.dj">DJ:n soittamaan</button>
      </div>

      <table style="margin-top: 1em;">
        <thead>
          <tr>
            <th style="vertical-align: bottom"><h2 style="margin: 0;">Kuvaus</h2></th>
            <th style="text-align: right;">Ohjeellinen hintahaarukka<br/>(sis. alv 24%)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="nothingSelected || needs.pa || needs.lights && (!needs.dj && !needs.mixing && !needs.live)">
            <td colspan="2">
              <div style="display: inline-block; width: 60%">
                <b>Ääni- ja valotekniikka</b>
              </div>
              <div style="display: inline-block; width: 40%;text-align: right; vertical-align: top;" nowrap>150 - 500 €</div>

              <div>Toimitamme tapahtumaan sopivan äänentoiston ja valotekniikan sekä valvomme tapahtuman toteutuksen. Palvelu soveltuu esim. häihin, pikkujouluihin, yritystilaisuuksiin, juhliin...</div><br/>

              <div class="price-modifiers">
                <b>Hintaan vaikuttavat tekijät</b>
                <ul>
                  <li>Toimitusaika, äänentoiston koko & tarve, valojen ja efektien määrä</li>
                </ul>
              </div>
            </td>
          </tr>
          <tr v-if="nothingSelected || needs.mixing || needs.pa || needs.lights && (!needs.dj && !needs.live)">
            <td colspan="2">
              <div style="display: inline-block; width: 60%">
                <b>Äänentoisto-, valo- ja miksauspalvelut bändeille</b>
              </div>
              <div style="display: inline-block; width: 40%;text-align: right; vertical-align: top;" nowrap>150 - 500 €</div>

              <div>Räätälöimme palvelumme bändinne tarpeen mukaan, oli sitten kyseessä pelkkä miksaus tai täysi show valojen kera. Teemme soundcheckin ja noin kahden tunnin showtimen-miksauksen. Toimitamme bändin tarpeisiin soveltuvan äänentoiston ja valotekniikan. Hoidamme sekä miksauksen että valojen ajon.</div><br/>
              <div class="price-modifiers">
                <b>Hintaan vaikuttavat tekijät</b>
                <ul>
                  <li>Toimituksen sisältö: Pelkkä miksaus? Pelkkä PA? Vain Valot? Vai kenties kaikki yhdessä?</li>
                  <li>Kokonaistoimituksen kesto (myös soundcheck &lt;-> showtime väli, sekä mahdolliset matkat)</li>
                  <li>Kaluston sisältö (pieni vs iso + valojen ja oheistekniikan määrä)</li>
                </ul>
              </div>
            </td>
          </tr>
          <tr v-if="nothingSelected || needs.dj && (!needs.mixing && !needs.live)">
            <td colspan="2">
              <div style="display: inline-block; width: 60%">
                <b>DJ-palvelut</b>
              </div>
              <div style="display: inline-block; width: 40%;text-align: right; vertical-align: top;" nowrap>alk. 150 €</div>

              <div>Vastaamme illan tanssimusiikin esittämisestä tilaajan tarpeiden mukaan. Oli kyseessä sitten 90's teema-ilta, rock/heavy-henkinen tapahtuma, EDM/dance-ilta tai radiohitteihin nojaava viihdepläjäys, tarjoamme juuri teidän tapahtumaanne sopivan musiikin! </div><br/>
              <div class="price-modifiers">
                <b>Hintaan vaikuttavat tekijät</b>
                <ul>
                  <li>Aloitushinta 150€. Sisältää 1.5h juonnettua tanssiaikaa. Lisätunnit 75€ / h.</li>
                  <li><b>Mikäli DJ-palvelu tilataan muiden palveluiden lisäpalveluna niin toimitus saatavilla edullisempaan pakettihintaan. Pyydä lisätietoja!</b></li>
                </ul>
              </div>
            </td>
          </tr>
          <tr v-if="nothingSelected || needs.live && (!needs.dj)">
            <td colspan="2">
              <div style="display: inline-block; width: 60%">
                <b>Live-musiikkia by For Momentum</b>
              </div>
              <div style="display: inline-block; width: 40%;text-align: right; vertical-align: top;" nowrap>300 - 500 €</div>

              <div>Soitamme monipuolisesti musiikkia 80, 90 ja 2000-2020 luvuilta ja käynnistämme iltanne! Toimitus soveltuu erinomaisesti mm. taustamusiikiksi illan istujaisiin, kauppakeskusten ja ravintoloiden ohjelmistoon sekä pikkujouluihin.</div><br/>
              <div class="price-modifiers">
                <b>Hintaan vaikuttavat tekijät</b>
                <ul>
                  <li>Esiintymisen kesto. 45min / 2 x 45min</li>
                  <li><b>Yhdistä esitykseen DJ-palvelu ja saat koko illan viihteen edullisempaan pakettihintaan. Pyydä lisätietoja!</b></li>
                </ul>
              </div>
            </td>
          </tr>
          <tr v-if="nothingSelected || needs.mixing || needs.pa || needs.live || needs.pa || needs.lights">
            <td colspan="2">
              <div style="display: inline-block; width: 60%">
                <b>Kokonaisratkaisu, eli "kaikkea paljon ja sekaisin"!</b>
              </div>
              <div style="display: inline-block; width: 40%;text-align: right; vertical-align: top;" nowrap>600 - 1200 € / päivä</div>

              <div>Toimitamme illan viihteen kokonaispaketin -- Toimitus voi sisältää esim. tekniikan (äänen & valot), live-musiikkia ja DJ:n soittamat lopputanssit! Oli kyse sitten bändi-illasta, firman pikkujouluista oheisohjelmineen, tai pikkufestareista, järjestämme unohtumattomat bileet.</div><br/>
              <div class="price-modifiers">
                <b>Hintaan vaikuttavat tekijät</b>
                <ul>
                  <li>Arvioimme kokonaisuuden tarpeidenne mukaan: Illan kesto, tilattava ohjelma & tarvittava tekniikka</li>
                </ul>
              </div>

            </td>
          </tr>
       </tbody>
      </table>
    </section>

</template>

<script>
export default {
  name: "prices-view",
  computed: {
    nothingSelected() {
      return !this.needs.pa && !this.needs.lights && !this.needs.live && !this.needs.dj && !this.needs.mixing;
    }
  },
  data: () => ({
    needs: {
      pa: false,
      lights: false,
      live: false,
      dj: false,
      mixing: false
    }
  })
}
</script>

<style lang="scss">
section.post {
  button.button {
    border: 0;
    box-shadow: none;

    background: #eee;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &.active {
      background: #58a758;
      color: white !important;
    }
  }
}
@media screen and (max-width: 980px) {
  #price-requirements button {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 0.5em;
  }
}
</style>