<template>
  <!-- Wrapper -->
  <div id="wrapper" class="fade-in">

    <!-- Intro -->
    <div id="intro">
      <div id="intro-wrapper">
        <h1>For Momentum</h1>
        <h2 style="margin-bottom: 0.25em;">Äänentoisto, miksaus ja viihdepalvelut</h2>
        <h4 style="margin-bottom: 2.5em;">Mikkelin seudulla ja lähiympäristössä</h4>
        <p>
          Toimitamme tarpeisiinne sopivan äänentoiston, upean valoshown, <br/>sekä musiikkia niin live-bändinä kuin täyden palvelun DJ-ohjelmistolla.
        </p>
      </div>
      <ul class="actions">
        <li><a href="#header" class="button icon solid solo fa-arrow-down scrolly">Jatka</a></li>
      </ul>
    </div>

    <!-- Header -->
    <header id="header">
      <a href="index.html" class="logo">For Momentum</a>
    </header>

    <!-- Nav -->
    <nav id="nav">
      <ul class="links">
        <li :class="{ 'active': view == 'home' }"><a href="#home" @click="setView('home')">Palvelumme</a></li>
        <li :class="{ 'active': view == 'live-music' }"><a href="#live-music" @click="setView('live-music')">Live-musiikki</a></li>
        <li :class="{ 'active': view == 'pricing' }"><a href="#pricing" @click="setView('pricing')">Hinnasto</a></li>
        <li :class="{ 'active': view == 'contact' }"><a href="#home" @click="setView('contact', () => { scrollToContacts(); })">Yhteydenotto</a></li>
      </ul>
      <ul class="icons">
        <li><a href="https://www.facebook.com/4momentum.fin" target="_blank" class="icon brands fa-facebook-f"><span class="label">Facebook</span></a></li>
        <!-- <li><a href="#" class="icon brands fa-instagram"><span class="label">Instagram</span></a></li> -->
      </ul>
    </nav>

    <select @change="e => { setView(e.target.value) }" id="mobile-nav">
      <option value="home">Palvelumme</option>
      <option value="live-music">Live-musiikki</option>
      <option value="pricing">Hinnasto</option>
      <option value="contact">Yhteydenotto</option>
    </select>

    <!-- Main -->
    <div id="main">

      <home-view v-if="view == 'home'" @set-view="(v) => { setView(v); }"></home-view>
      <live-music v-if="view == 'live-music'"></live-music>
      <prices-view v-if="view == 'pricing'"></prices-view>
      <contact-view v-if="view == 'contact'"></contact-view>

    </div>

    <!-- Footer -->
    <footer id="footer">
      <section style="background: black;">
        <img src="@/assets/logo.png" style="width: 100%;">
      </section>
      <section class="split contact">
        <section>
          <h3>Puhelin</h3>
          <p>
            <a href="tel:0400472668">+358 400 472 668 / Janne</a><br/>
            <a href="tel:0408232228">+358 40 823 2228 / Harri</a>
          </p>
        </section>
        <section>
          <h3>Sähköposti</h3>
          <p><a href="#">4momentum.fin@gmail.com</a></p>
        </section>
        <section>
          <h3>Media</h3>
          <ul class="icons alt">
            <li><a href="https://www.facebook.com/4momentum.fin" target="_blank" class="icon brands alt fa-facebook-f"><span class="label">Facebook</span></a></li>
            <!-- <li><a href="#" class="icon brands alt fa-instagram"><span class="label">Instagram</span></a></li> -->
          </ul>
        </section>
      </section>
    </footer>

  </div>
</template>

<script>
import HomeView from './home'
import LiveMusic from './live-music'
import PricesView from './prices'
import ContactView from './contact-view'

export default {
  name: "app-layout",
  components: {
    LiveMusic,
    HomeView,
    PricesView,
    ContactView
  },
  data: () => ({
    view: "home"
  }),
  methods: {
    setView(view, callback) {
      this.view = view;


      setTimeout(() => {
          document.getElementById('nav').scrollIntoView({ behavior: "smooth" });
      }, 100);

      if(callback)
        callback();
    },
    scrollToContacts() {
      console.log('juujuu');
    }
  },
  created() {
    var view = window.location.hash.substr(1);
    if(view)
      this.view = view;
  },
  mounted() {

  }
}
</script>

<style lang="scss">
#nav {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  li {
    cursor: pointer;
  }
}
#mobile-nav {
  display: none;
  width: calc(100% - 4rem);
  margin: auto;
  margin-bottom: 1em;
  padding: 1em;
  height: unset;
  padding-left: 2em;
}
@media screen and (max-width: 980px) {

  #intro {
    min-height: 60vh;
  }
  #mobile-nav {
    display: block;
    background: white !important;
  }
}
</style>