import { createApp } from 'vue'
import App from './index.vue'

import lodash from 'lodash'

const Momentum = createApp(App);
Momentum.config.globalProperties.$_ = lodash;
Momentum.mount('#app');


