<template>

      <!-- Featured Post -->
      <article class="post featured">
        <header class="major">
          <h2><a href="#">Ääntä, valoa ja musiikkia</a></h2>
          <p>Tuotamme tapahtumapalvelut yhden luukun taktiikalla. Toimitamme niin ääni- ja valotekniikan, kuin myös live-musiikkia joko DJ:n soittamana tai live-bändinä. Tuotamme myös monipuolisesti tapahtumatuotantoon liittyviä palveluita, kuten
            esimerkiksi miksauspalveluita ja valojen ajoja bändeille.</p>
        </header>
        <a href="#" class="image main"><img src="assets/images/img2.jpg" alt="" /></a>
        <ul class="actions special">
          <li><a href="#header" @click="$emit('set-view', 'pricing')" class="button large">Lue lisää palvelupaketeistamme</a></li>
        </ul>
      </article>

      <!-- Posts -->
      <section class="posts">
        <article>
          <header>
            <h2><a href="#">Tapahtumat & tekniikka</a></h2>
          </header>
          <a href="#" class="image fit"><img src="../public/assets/images/tekniikka.jpg" alt="" /></a>
          <p>Onnistuneen tapahtuman perusta on toimiva tekniikka! Tapahtuman luonne asettaa raamit tekniikalle ja pystymme toteuttamaan tekniikan niin pienimuotoisiin illanistujaisiin, kauppakeskusten käytäville kuin myös yökerhojen lavoille. </p>
          <ul class="actions special">
            <li><a href="#header" @click="$emit('set-view', 'pricing')" class="button">Lue lisää</a></li>
          </ul>
        </article>
        <article>
          <header>
            <h2><a href="#">Palvelut bändeille</a></h2>
          </header>
          <a href="#" class="image fit"><img src="../public/assets/images/kj.jpg" alt="" /></a>
          <p>Toteutamme miksauskeikat niin trubaduureille kuin 10 hengen bändille. Panostamme erinomaiseen äänenlaatuun sekä tuotamme näyttävän valoshown. Vangitse kuulijat ja vie esitys uudelle tasolle!</p>
          <ul class="actions special">
            <li><a href="#header" @click="$emit('set-view', 'pricing')" class="button">Lue lisää</a></li>
          </ul>
        </article>
        <article>
          <header>
            <h2><a href="#">DJ-palvelut</a></h2>
          </header>
          <a href="#" class="image fit"><img src="../public/assets/images/dj.jpg" alt="" /></a>
          <p>Bileet lähtevät lapasesta kun teitä viihdyttää asiantunteva DJ. Laitamme tanssijalan vipattamaan kaiken ikäisille kuuntelijoille, oli kyseessä sitten 90's teemailta, tämän päivän radiohitit tai tietyn musiikkigenren erikoisilta.</p>
          <ul class="actions special">
            <li><a href="#header" @click="$emit('set-view', 'pricing')" class="button">Lue lisää</a></li>
          </ul>
        </article>
        <article>
          <header>
            <h2><a href="#">Live-musiikkia</a></h2>
          </header>
          <a href="#" class="image fit"><img src="../public/assets/images/jw.jpg" alt="" /></a>
          <p>For Momentum toimii myös bändinä! Toimitamme päräyttävän live-setin akustisena duona ja tarjoamme yleisölle tuttuja biisejä niin 80, 90, kuin myös 2000-2020 luvuilta! </p>
          <ul class="actions special">
            <li><a href="#header" @click="$emit('set-view', 'live-music')" class="button">Lue lisää</a></li>
          </ul>
        </article>
      </section>
</template>

<script>
export default {
  name: "home-view",
  emits: ['set-view']
}
</script>