<template>

  <!-- Post -->
    <section class="post">
      <header class="major">
        <h1>Live-musiikkia tapahtumaasi</h1>
        <p>For Momentum on akustinen duo, joka heittää päräyttävän akustisen setin eri vuosikymmenten hittejä ja huteja yhdistellen. Olemme heittäneet keikkaa niin synttäreillä, illan istujaisissa, markkinoilla, ravintoloiden lauteilla kuin myös konserttitalon aulassa ruokailijoita ilahduttamassa.</p>
        <p>Laajaan biisivalikoimaamme kuuluu kappaleita mm. seuraavilta artisteilta:
          Chris Cornell, Tehosekoitin, Offspring, Papa Roach, Swedish House Mafia, System Of A Down, Nightwish, Disturbed, Oasis, Pearl Jam, Soundgarden, Jimi Hendrix, Green Day, Neljä Ruusua, Ugly Kid Joe, Billy Idol, Dio, Mokoma, Amorphis, Foofighters...
        </p>
        <p>Ota rohkeasti yhteyttä ja kysy lisää!</p>
      </header>

      <iframe style="width: 31%; margin-right: 1vw;" src="https://www.youtube.com/embed/lr3BsRNqsSw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe style="width: 31%; margin-right: 1vw;" src="https://www.youtube.com/embed/4uTNbShbluk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe style="width: 31%; margin-right: 1vw;" src="https://www.youtube.com/embed/FI2WR7jdTxA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

    </section>

</template>

<script>
export default {
  name: "live-music",
  computed: {
  },
  data: () => ({
    needs: {
      pa: false,
      lights: false,
      live: false,
      dj: false,
      mixing: false
    }
  })
}
</script>

<style lang="scss">
section.post {
  button.button {
    border: 0;
    box-shadow: none;

    background: #eee;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &.active {
      background: #58a758;
      color: white !important;
    }
  }
}
</style>